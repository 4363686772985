import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk'
import { useCallback, useState } from 'react'
import { Alert, Button } from 'reactstrap'
import { Transaction } from '../types'
import { BatchTransactionList } from './BatchTransactionList'
import { TokenList } from './TokenList'
import { TransactionDetails } from '@safe-global/safe-gateway-typescript-sdk'
import { BaseTransaction } from '@safe-global/safe-apps-sdk'
import { TokenContractMap } from '../App'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { erc20ABI } from '../contracts/erc20ABI'
type TransactionStep = 'review-transactions' | 'sending-tx' | 'tx-sent'
export const TransactionCreationScreen = ({
    transactions,
    tokenSymbols,
}: {
    transactions: Transaction[]
    tokenSymbols: TokenContractMap
}) => {
    const { sdk, safe } = useSafeAppsSDK()
    const [txStep, setTxStep] = useState<TransactionStep>('review-transactions')
    const [safeTransaction, setSafeTransaction] = useState<TransactionDetails | undefined>()

    const [error, setError] = useState<string | undefined>()

    const sendTx = useCallback(async () => {
        try {
            setTxStep('sending-tx')
            // here is where we can encode the data
            const { safeTxHash } = await sdk.txs.send({
                txs: await buildAssetTransfers(transactions, tokenSymbols),
            })
            const safeTx = await sdk.txs.getBySafeTxHash(safeTxHash)
            setSafeTransaction(safeTx)
            setTxStep('tx-sent')
        } catch (e) {
            console.error(e)
            if (e instanceof Error) {
                setError(e.message)
            } else {
                setError('Unknown error, see console for more details.')
            }
        }
    }, [safe, sdk, transactions, tokenSymbols])

    if (error) {
        return (
            <Alert
                color="danger"
                style={{
                    marginBottom: '32px',
                    width: '600px',
                }}
            >
                <h4 className="alert-heading">Something went wrong!</h4>
                <p>{error}</p>
                <p className="mb-0">
                    Contact support at <a href={`digitalomnibus@tropicalgalaxy.io`}>View Transaction</a>
                </p>
            </Alert>
        )
    }

    if (txStep === 'review-transactions')
        return (
            <>
                <Alert
                    style={{
                        marginBottom: '32px',
                        width: '600px',
                    }}
                    color="info"
                >
                    Please review the list of transactions to ensure all the transactions are correct before sending
                </Alert>
                {tokenSymbols.size > 0 && (
                    <>
                        <TokenList tokenSymbols={tokenSymbols} listOfTransactions={transactions ?? []} />
                    </>
                )}
                <BatchTransactionList transactions={transactions} tokenSymbols={tokenSymbols} />
                <div
                    style={{
                        marginTop: '32px',
                        width: '600px',
                        marginBottom: '64px',
                    }}
                >
                    <Button
                        color="primary"
                        style={{
                            cursor: 'pointer',
                        }}
                        block
                        onClick={(e) => {
                            e.preventDefault()
                            sendTx()
                        }}
                        size="lg"
                    >
                        Send Tokens
                    </Button>
                </div>
            </>
        )
    if (txStep === 'sending-tx') {
        return (
            <Alert
                color="info"
                style={{
                    marginBottom: '32px',
                    width: '600px',
                }}
            >
                <h4 className="alert-heading">Creating transaction...</h4>
                <p>Please wait while we create the transaction and submit it to the safe transaction service</p>
            </Alert>
        )
    }
    if (txStep === 'tx-sent') {
        return (
            <Alert
                color="success"
                style={{
                    marginBottom: '32px',
                    width: '600px',
                }}
            >
                <h4 className="alert-heading">Transaction Created Successfully!</h4>
                <p>
                    The transaction has been created and is now pending. You can view the transaction in the safe
                    transaction list
                </p>
                <hr />
                <p className="mb-0">
                    <a
                        target="_blank"
                        href={`https://app.safe.global/transactions/tx?id=${safeTransaction?.txId}&safe=${safe.safeAddress}`}
                    >
                        View Transaction
                    </a>
                </p>
            </Alert>
        )
    }
    return <></>
}

export const buildAssetTransfers = async (
    transactions: Transaction[],
    tokenSymbols: TokenContractMap,
): Promise<BaseTransaction[]> => {
    const txList: BaseTransaction[] = []
    for (const transfer of transactions) {
        // first check to see if its a token transaction
        if (transfer.tokenAddress.toLowerCase() === 'native' || transfer.tokenAddress === '') {
            const token = tokenSymbols.get('native')
            if (!token) throw new Error(`Token not found for address ${transfer.tokenAddress}`)

            // native transfer
            const valueData = toWei(transfer.amount, token.decimals)
            txList.push({
                to: transfer.toAddress,
                value: valueData.toFixed(),
                data: '0x',
            })
        }
        // if not then its erc20
        else {
            // get the token details from the map
            const token = tokenSymbols.get(transfer.tokenAddress)
            if (!token) throw new Error(`Token not found for address ${transfer.tokenAddress}`)
            const ercToken = new ethers.Contract(transfer.tokenAddress, erc20ABI)

            // ERC20 transfer

            const valueData = toWei(transfer.amount, token.decimals)
            console.log({ ercToken })

            const { data } = await ercToken
                .getFunction('transfer')
                .populateTransaction(transfer.toAddress, valueData.toFixed())
            txList.push({
                to: transfer.tokenAddress,
                value: '0',
                data: data,
            })
        }
    }
    return txList
}

export function toWei(amount: string | number | BigNumber, decimals: number): BigNumber {
    let res = new BigNumber(10).pow(decimals).multipliedBy(amount)
    const decimalPlaces = res.decimalPlaces()
    if (decimalPlaces != null && decimalPlaces > 0) {
        res = res.decimalPlaces(0, BigNumber.ROUND_DOWN)
    }
    return res
}
