import { CSSProperties, useRef } from 'react'
import { create } from 'ethereum-blockies'

export const Image = ({
    src,
    toAddress,
    style,
    size,
}: {
    src: string
    toAddress: string
    style?: CSSProperties
    size: number
}) => {
    const ref = useRef<HTMLImageElement | null>(null)

    function handleFallback() {
        // Nullify the error event for subsequent calls
        if (ref.current) {
            ref.current.onerror = null
            ref.current.src = create({
                seed: toAddress.toLowerCase(),
                size: 8,
                scale: 8,
            }).toDataURL()
        }
    }

    return (
        <img
            style={style}
            height={size.toString()}
            width={size.toString()}
            ref={ref}
            src={src}
            onError={handleFallback}
        />
    )
}
