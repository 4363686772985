import { NoSafeProviderFooter } from './components/Footer'

import Lottie from 'react-lottie'
import * as animationData from './animation.json'
import { CSSProperties, ReactElement, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

const linkStyle: CSSProperties = {
    color: '#00BFFF',

    textShadow: `rgb(0 164 205 / 50%) 0px 4px 4px, rgb(1 152 203 / 50%) 0px 0px 2px, rgb(0 151 201) 0px 0px 4px, rgb(1 158 198 / 90%) 0px 0px 10px, rgb(1 166 208 / 60%) -1px 8px 13px`,

    display: 'inline-block',
    textAlign: 'center',
    textDecoration: 'underline',
    textUnderlineOffset: '0.2rem',
}

export const MarketingSite = () => {
    const [setupInstructionsVisible, setSetupInstructionsVisible] = useState(false)
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '64px',
                    marginBottom: '64px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src={'/trinance.svg'} width="48" height="48" />
                <h1
                    style={{
                        marginTop: '0',
                        textAlign: 'center',
                        letterSpacing: '1',
                        fontWeight: '300',
                        marginLeft: '1rem',
                        marginBottom: '0',
                        color: '#00BFFF',
                        textShadow: `rgb(0 164 205 / 50%) 0px 4px 4px, rgb(1 152 203 / 50%) 0px 0px 2px, rgb(0 151 201) 0px 0px 4px, rgb(1 158 198 / 90%) 0px 0px 10px, rgb(1 166 208 / 60%) -1px 8px 13px`,
                    }}
                >
                    Digital Omnibus
                </h1>
            </div>
            <div
                style={{
                    minHeight: '300px',
                    backgroundColor: '#161616',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    padding: '3rem',
                }}
            >
                <h2
                    style={{
                        textAlign: 'center',
                        letterSpacing: '1',
                        fontWeight: '200',
                        fontSize: '2.5rem',
                        color: 'white',
                        marginBottom: '2rem',
                    }}
                >
                    Web3 Transaction Infrastructure
                </h2>
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <span
                        style={{
                            marginRight: '0.5rem',
                            marginLeft: '0.5rem',
                            color: '#00BFFF',
                        }}
                    >
                        Preform complex digital asset transaction confidently and securely.
                    </span>
                </div>
                <a
                    style={{ ...linkStyle, marginTop: '32px' }}
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault()
                        setSetupInstructionsVisible(true)
                    }}
                >
                    Use Digital Omnibus on Gnosis Safe
                </a>
                {/* <div
                    style={{
                        color: 'white',
                        fontSize: '12px',
                        textAlign: 'center',
                        marginTop: '2rem',
                    }}
                >
                    Last Block 203,293 @ 12:00:00
                </div> */}
            </div>
            <div
                style={{
                    marginTop: '32px',
                    marginBottom: '32px',
                }}
            >
                <SideBySidePannels
                    left={
                        <div
                            style={{
                                width: '400px',
                            }}
                        >
                            <h3
                                style={{
                                    marginBottom: '32px',
                                }}
                            >
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        // dotted underline
                                        textDecorationStyle: 'dotted',
                                        // offest it a bit
                                        textUnderlineOffset: '4px',
                                    }}
                                >
                                    $10m
                                </span>
                                + volume per month
                            </h3>
                            <p
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Trusted By institutions to regularly process over{' '}
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                        // dotted underline
                                        textDecorationStyle: 'dotted',
                                        textUnderlineOffset: '3px',
                                    }}
                                >
                                    $10m
                                </span>{' '}
                                in crypto currency token transfers per month and growing
                            </p>
                        </div>
                    }
                    right={
                        <div>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: animationData,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice',
                                    },
                                }}
                                height={400}
                                speed={0.5}
                                width={400}
                            />
                        </div>
                    }
                />
            </div>
            <Modal
                isOpen={setupInstructionsVisible}
                toggle={() => {
                    setSetupInstructionsVisible(false)
                }}
                style={{
                    border: '1px solid #00BFFF',
                }}
                size="lg"
                centered
            >
                <ModalBody
                    style={{
                        backgroundColor: 'black',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '32px',
                        }}
                    >
                        <h3
                            style={{
                                flexGrow: 1,
                                margin: '0',
                            }}
                        >
                            Setup
                        </h3>
                        <div
                            style={{
                                color: '#00BFFF',
                                fontSize: '24px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setSetupInstructionsVisible(false)
                            }}
                        >
                            x
                        </div>
                    </div>

                    <div
                        style={{
                            marginBottom: '16px',
                        }}
                    >
                        1.Navigate to your{' '}
                        <a target="blank" style={linkStyle} href="https://app.safe.global/">
                            gnosis safe
                        </a>{' '}
                        and go to 'APPS' page, an click on 'My custom apps'{' '}
                    </div>
                    <img
                        src={'/images/setup-1.png'}
                        width="100%"
                        style={{
                            marginBottom: '64px',
                        }}
                    />
                    <div
                        style={{
                            marginBottom: '16px',
                        }}
                    >
                        2.Add a new custom safe with with the url{' '}
                        <a target="_blank" style={linkStyle} href="https://digitalomnibus.tropicalgalaxy.io/">
                            https://digitalomnibus.tropicalgalaxy.io/
                        </a>
                        , Agree to its usage and click on 'Add'.
                    </div>
                    <img src={'/images/setup-2.png'} width="100%" />
                </ModalBody>
            </Modal>
            <NoSafeProviderFooter />
        </>
    )
}

const SideBySidePannels = ({ left, right }: { left: ReactElement; right: ReactElement }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            <div
                style={{
                    minWidth: '50%',
                    padding: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {left}
            </div>
            <div
                style={{
                    minWidth: '50%',
                    padding: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {right}
            </div>
        </div>
    )
}
