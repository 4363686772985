import React from 'react'
import ReactDOM from 'react-dom/client'
import SafeProvider from '@safe-global/safe-apps-react-sdk'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import { MarketingSite } from './MarketingSite'

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Root element not found on public/index.html')

/**
 * App wrapper to show a different site depending on if we are being called by safe or not
 */
const CheckOnSafeSite = ({ safeApp, MarketingSite }: { safeApp: React.ReactNode; MarketingSite: React.ReactNode }) => {
    const isSafe =
        window &&
        window.location &&
        window.location.ancestorOrigins &&
        window.location.ancestorOrigins.contains('https://app.safe.global')
    return isSafe ? safeApp : MarketingSite
}

const root = ReactDOM.createRoot(rootElement)
root.render(
    <React.StrictMode>
        <CheckOnSafeSite
            safeApp={
                <SafeProvider
                    loader={
                        <>
                            <h1>Waiting for Safe... </h1>
                        </>
                    }
                >
                    <App />
                </SafeProvider>
            }
            MarketingSite={<MarketingSite />}
        />
    </React.StrictMode>,
)
