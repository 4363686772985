import { create } from 'ethereum-blockies'
import { Transaction } from '../types'
import { TokenContractMap } from '../App'
import { Image } from './Image'

export const BatchTransactionListItem = ({
    transaction,
    tokenSymbols,
}: {
    transaction: Transaction
    tokenSymbols: TokenContractMap
}) => {
    let tokenSymbol = tokenSymbols.get(transaction.tokenAddress)?.symbol
    if (transaction.tokenAddress.toLowerCase() === 'native' || transaction.tokenAddress === '') {
        tokenSymbol = tokenSymbols.get('native')?.symbol
    }
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.5rem',
                borderBottom: '1px solid #303030',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                }}
            >
                <img
                    height="32"
                    width="32"
                    style={{ borderRadius: '50%', marginRight: '8px' }}
                    src={create({
                        seed: transaction.toAddress.toLowerCase(),
                        size: 8,
                        scale: 8,
                    }).toDataURL()}
                />

                <div
                    style={{
                        flexGrow: 1,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        {transaction.amount}
                        <span
                            style={{
                                color: '#929292',
                                fontSize: '12px',
                                marginLeft: '4px',
                                flexGrow: 1,
                            }}
                        >
                            {tokenSymbol}
                        </span>
                        <span
                            style={{
                                fontSize: '12px',
                                marginLeft: '4px',
                            }}
                        >
                            {tokenSymbol}
                            <Image
                                src={`/logos/${tokenSymbol}.svg`}
                                size={16}
                                style={{ borderRadius: '50%', marginLeft: '4px' }}
                                toAddress={transaction.tokenAddress}
                            />
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                color: '#929292',
                                fontSize: '12px',
                                lineHeight: '14px',
                                display: 'flex',
                                marginRight: '8px',
                            }}
                        >
                            TO:{transaction.toAddress}
                        </div>
                        <img
                            src={'/external-link-muted.svg'}
                            width="12"
                            height="12"
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
