export const UploadInstructions = ({
    setShowInstructions,
    showInstructions,
}: {
    setShowInstructions: (showInstructions: boolean) => void
    showInstructions: boolean
}) => {
    return (
        <>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <a
                    style={{
                        textDecoration: 'none',
                        color: '#00BFFF',
                        textShadow: `rgb(0 164 205 / 50%) 0px 4px 4px, rgb(1 152 203 / 50%) 0px 0px 2px, rgb(0 151 201) 0px 0px 4px, rgb(1 158 198 / 90%) 0px 0px 10px, rgb(1 166 208 / 60%) -1px 8px 13px`,
                        borderBottom: '1px solid #00BFFF',
                        display: 'inline-block',
                    }}
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault()
                        setShowInstructions(!showInstructions)
                    }}
                >
                    Back to Upload
                </a>
            </div>{' '}
            <div>
                <h2>Instructions</h2>
                <p>To distribute tokens to multiple addresses, you can upload a CSV file with the following format:</p>
                <pre>{`token_address,to_address,amount`}</pre>
                <p>
                    In order to send native tokens either leave the 'token_address' blank or put 'native' in place of
                    the address.
                </p>
            </div>
        </>
    )
}
