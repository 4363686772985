import { ReactNode, useState, useCallback } from 'react'
import { Collapse as BootstrapCollapse } from 'reactstrap'
export const Collapse = ({ children, title }: { children: ReactNode; title: ReactNode }) => {
    const [isOpen, setIsOpen] = useState(true)
    const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '600px',
                }}
            >
                <h2>{title}</h2>
                <div
                    onClick={toggle}
                    style={{
                        cursor: 'pointer!important',
                    }}
                >
                    <img
                        src={'/chevron.svg'}
                        width="24"
                        height="24"
                        style={{
                            transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
                            transition: 'transform 0.2s ease-in-out',
                            cursor: 'pointer',
                        }}
                    />
                </div>
            </div>
            <BootstrapCollapse isOpen={isOpen}>{children}</BootstrapCollapse>
        </>
    )
}
