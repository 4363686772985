import Decimal from 'decimal.js'
import { Transaction } from '../types'
import { Collapse } from './Collapse'
import { TokenListItem } from './TokenListItem'
import { TokenContractMap } from '../App'

export const TokenList = ({
    tokenSymbols,
    listOfTransactions,
}: {
    tokenSymbols: TokenContractMap
    listOfTransactions: Transaction[]
}) => {
    return (
        <div
            style={{
                marginBottom: '32px',
            }}
        >
            <Collapse title={`Tokens(${tokenSymbols.size})`}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            width: '600px',
                            border: '1px solid #303030',
                            borderBottom: 'none',
                        }}
                    >
                        {Array.from(tokenSymbols.keys()).map((tokenAddress) => (
                            <TokenListItem
                                key={tokenAddress}
                                tokenAddress={tokenAddress}
                                tokenSymbol={tokenSymbols.get(tokenAddress)?.symbol || 'Unknown'}
                                amountToTransfer={listOfTransactions?.reduce((acc, cur) => {
                                    if (cur.tokenAddress === tokenAddress) {
                                        return acc.add(new Decimal(cur.amount))
                                    }
                                    return acc
                                }, new Decimal(0))}
                            />
                        ))}
                    </div>
                </div>
            </Collapse>
        </div>
    )
}
