import React, { useEffect, useState } from 'react'
import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk'
import { Footer } from './components/Footer'
import { FileInput } from './components/FileInput'
import { ethers } from 'ethers'
import { Transaction } from './types'
import { TransactionCreationScreen } from './components/TransactionCreationScreen'

export type TokenContractMap = Map<
    string,
    {
        name: string
        symbol: string
        decimals: number
    }
>

const SafeApp = (): React.ReactElement => {
    const { sdk } = useSafeAppsSDK()
    const [listOfTransactions, setListOfTransactions] = useState<Transaction[] | undefined>()

    const [tokenSymbols, setTokenSymbols] = useState<TokenContractMap>(
        new Map<
            string,
            {
                name: string
                symbol: string
                decimals: number
            }
        >(),
    )

    /**
     * When the list of transactions changes we want to get the unique token addresses
     * so we can fetch their symbols from the smart contracts
     */
    useEffect(() => {
        const tokenAddresses = new Set(listOfTransactions?.map((transaction) => transaction.tokenAddress))
        const addressNameMap = new Map<
            string,
            {
                name: string
                symbol: string
                decimals: number
            }
        >()
        // now create a promise for each one where we call the smart contract to get the
        // symbol
        Promise.all(
            Array.from(tokenAddresses)
                .filter((address) => address !== '' && address.toLowerCase() !== 'native')
                .map(async (tokenAddress) => {
                    console.log({ tokenAddress })
                    // check to see if the token address is native or empty which means native aswell
                    if (tokenAddress === 'native' || tokenAddress === '') {
                        addressNameMap.set(tokenAddress, {
                            name: 'Ethereum',
                            symbol: 'ETH',
                            decimals: 18,
                        })
                        return
                    }

                    console.log({ tokenAddress })
                    const symbol = await sdk.eth.call([
                        {
                            to: tokenAddress,
                            data: '0x95d89b41',
                        },
                    ])
                    console.log({ symbol })
                    // decode the symbol
                    const decodedSymbol = ethers.AbiCoder.defaultAbiCoder().decode(['string'], symbol).toString()
                    const decodedName = await sdk.eth.call([
                        {
                            to: tokenAddress,
                            data: '0x06fdde03',
                        },
                    ])

                    const decodedDecimals = await sdk.eth.call([
                        {
                            to: tokenAddress,
                            data: '0x313ce567',
                        },
                    ])
                    console.log({ decodedSymbol })
                    addressNameMap.set(tokenAddress, {
                        name: ethers.AbiCoder.defaultAbiCoder().decode(['string'], decodedName).toString(),
                        symbol: decodedSymbol,
                        decimals: Number(ethers.toBigInt(decodedDecimals)),
                    })
                }),
        ).then(async () => {
            // after we need to set the token symbols

            // first lets check if there were any native token transactions
            //
            if (Array.from(tokenAddresses).some((address) => address !== '' && address.toLowerCase() !== 'native')) {
                const chainInfo = await sdk.safe.getChainInfo()
                addressNameMap.set('native', {
                    name: chainInfo.chainName,
                    symbol: chainInfo.nativeCurrency.symbol,
                    decimals: chainInfo.nativeCurrency.decimals,
                })
            }

            setTokenSymbols(addressNameMap)
        })

        //need to write some code to encode this transactions
    }, [listOfTransactions, setTokenSymbols])

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    marginTop: '3rem',
                    marginBottom: '3rem',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <img src={'/trinance.svg'} width="48" height="48" />
                <h1
                    style={{
                        marginTop: '0',
                        textAlign: 'center',
                        letterSpacing: '1',
                        fontWeight: '300',
                        marginLeft: '1rem',
                        marginBottom: '0',
                        color: '#00BFFF',
                        textShadow: `rgb(0 164 205 / 50%) 0px 4px 4px, rgb(1 152 203 / 50%) 0px 0px 2px, rgb(0 151 201) 0px 0px 4px, rgb(1 158 198 / 90%) 0px 0px 10px, rgb(1 166 208 / 60%) -1px 8px 13px`,
                    }}
                >
                    Digital Omnibus
                </h1>
            </div>

            {listOfTransactions ? (
                <TransactionCreationScreen transactions={listOfTransactions} tokenSymbols={tokenSymbols} />
            ) : (
                <FileInput
                    onUpload={(data) => {
                        setListOfTransactions(data)
                    }}
                />
            )}

            <Footer />
        </div>
    )
}

export default SafeApp
