export const FileUploadHeader = ({
    setShowInstructions,
    showInstructions,
}: {
    setShowInstructions: (showInstructions: boolean) => void
    showInstructions: boolean
}) => {
    return (
        <div
            style={{
                textAlign: 'center',
            }}
        >
            <span
                style={{
                    marginRight: '0.5rem',
                    marginLeft: '0.5rem',
                    color: 'white',
                }}
            >
                Upload Omnibus Distribution File
            </span>
            <span
                style={{
                    marginRight: '0.5rem',
                }}
            >
                |
            </span>

            <a
                style={{
                    textDecoration: 'none',
                    color: '#00BFFF',
                    textShadow: `rgb(0 164 205 / 50%) 0px 4px 4px, rgb(1 152 203 / 50%) 0px 0px 2px, rgb(0 151 201) 0px 0px 4px, rgb(1 158 198 / 90%) 0px 0px 10px, rgb(1 166 208 / 60%) -1px 8px 13px`,
                    borderBottom: '1px solid #00BFFF',
                    display: 'inline-block',
                }}
                href={'#'}
                onClick={(e) => {
                    e.preventDefault()
                    setShowInstructions(!showInstructions)
                }}
            >
                Instructions
            </a>
        </div>
    )
}
