import { useCallback, useRef, useState } from 'react'
import { UploadInstructions } from './UploadInstructions'
import { FileUploadHeader } from './FileUploadHeader'

export const FileInput = ({
    onUpload,
}: {
    onUpload: (
        data: {
            tokenAddress: string
            toAddress: string
            amount: string
        }[],
    ) => void
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null)

    const [showInstructions, setShowInstructions] = useState(false)

    const onFileUpload = useCallback((file: File) => {
        console.log(file)
        // check that it is a csv file
        if (file.type !== 'text/csv') {
            console.error('Invalid file type')
            return
        }
        // read the file and make sure the headers are correct
        const reader = new FileReader()
        reader.onload = (e) => {
            const content = e.target?.result as string
            const lines = content.split('\n')
            const headers = lines[0].split(',').map((header) => header.trim())
            if (headers.length !== 3) {
                console.error('Invalid headers')
                return
            }
            if (headers[0] !== 'token_address' || headers[1] !== 'to_address' || headers[2] !== 'amount') {
                console.error('Invalid headers')
                return
            }
            const data = lines.slice(1).map((line) => {
                const [tokenAddress, toAddress, amount] = line.split(',')
                return {
                    tokenAddress: tokenAddress.trim(),
                    toAddress: toAddress.trim(),
                    amount: amount.trim(),
                }
            })
            onUpload(data)
        }
        reader.readAsText(file)
    }, [])

    return (
        <div
            style={{
                minHeight: '300px',
                backgroundColor: '#161616',
                minWidth: '580px',
                maxWidth: '600px',
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                padding: '3rem',
            }}
        >
            {showInstructions ? (
                <UploadInstructions setShowInstructions={setShowInstructions} showInstructions={showInstructions} />
            ) : (
                <>
                    <FileUploadHeader setShowInstructions={setShowInstructions} showInstructions={showInstructions} />
                    <div
                        style={{
                            backgroundColor: '#1E1E1E',
                            flexGrow: 1,
                            marginTop: '1rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (fileInputRef.current) {
                                fileInputRef.current.click()
                            }
                        }}
                        onDragOver={(e) => {
                            e.preventDefault()
                        }}
                        onDrop={(e) => {
                            e.preventDefault()
                            const file = e.dataTransfer.files?.[0]
                            if (file) {
                                onFileUpload(file)
                            }
                        }}
                    >
                        <img
                            style={{
                                height: '48px',
                                width: '48px',
                                marginBottom: '16px',
                            }}
                            src={'/upload.svg'}
                        />
                        <div>Drag File Or Click Here</div>
                    </div>
                </>
            )}

            <input
                ref={fileInputRef}
                type="file"
                style={{
                    display: 'none',
                }}
                onChange={(e) => {
                    const file = e.target.files?.[0]
                    if (file) {
                        onFileUpload(file)
                    }
                }}
            />
        </div>
    )
}
