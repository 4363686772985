import { Transaction } from '../types'
import { Collapse } from './Collapse'
import { TokenContractMap } from '../App'
import { BatchTransactionListItem } from './BatchTransactionListItem'
export const BatchTransactionList = ({
    transactions,
    tokenSymbols,
}: {
    transactions: Transaction[]
    tokenSymbols: TokenContractMap
}) => {
    return (
        <Collapse title={`Transfers(${transactions.length})`}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        width: '600px',
                        border: '1px solid #303030',
                        borderBottom: 'none',
                    }}
                >
                    {transactions.map((transaction, index) => (
                        <BatchTransactionListItem
                            key={index + '-' + transaction.tokenAddress}
                            transaction={transaction}
                            tokenSymbols={tokenSymbols}
                        />
                    ))}
                </div>
            </div>
        </Collapse>
    )
}
